
.user-avatars {
  width: 5em;
  flex: 0 0 5em;
  height: 3em;
  position: relative;
  inset-inline-start: -2px;

  .user-avatar {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 3;

    &:nth-child(1) {
      inset-inline-start: 1.2em;
      z-index: 2;
    }

    &:nth-child(2) {
      inset-inline-start: 2.4em;
      z-index: 1;
    }

    &:nth-child(n + 4) {
      display: none;
    }
  }
}

.content {
  max-width: calc(100% - 6em);
}
.last-message-content,
.conversation-participants {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.conversation-intro {
  position: relative;
  padding-inline: var(--margin-m);

  &.current,
  &.active {
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      inset-inline-start: 0em;
      width: 3px;
      height: 100%;
      background-color: var(--c-lead);
      // border-radius: 0 50% 50% 0;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    height: 1px;
    inset-inline-start: 0;
    width: 100%;
    background-color: var(--c-gray-2);
    margin-top: var(--margin-l);
    margin-inline: auto;
  }
}
